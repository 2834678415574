@import url('https://fonts.googleapis.com/css?family=Cormorant:400,600');

*{
  font-family: 'Cormorant', serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App{
  position: relative;
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.poem, .info-button{
  user-select: none;
}
.one-poem{
  margin-top: 13vh;
  cursor: pointer;
  box-sizing: border-box;
  padding: 2rem;
}
.poem-week{
  text-align: center;
}
.poem-title{
  margin: 14vh 0 2rem 0;
}

h1{
  font-size: 2rem;
}
h2{
  font-size: 1.5rem;
  letter-spacing: 1px;
  z-index: 99;
}
h3{
  font-weight: 600;
  font-size: 1rem;
}
p{
  font-size: 1rem;
  line-height: 1.5;
}

/* ----MODAL---- */

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  color: white;
  padding-bottom: 3rem;
  z-index: 9;
  overflow: scroll;
  cursor: auto;
}
.modal-content{
  width: 50%;
  margin:  0 3rem;
  margin-top: 5vh;
}
.white{
  color: white;
  z-index: 9999;
}
.App-title{
  margin-bottom: 3rem;
  letter-spacing: 1px;
}
.App-intro p{
  margin-bottom: 1rem;
  letter-spacing: 1px;
}
.info-button{
  margin-bottom: 2rem;
  position: fixed;
  bottom: 0;
  right: 4rem;
  padding: 3rem;
  cursor: pointer;
  text-decoration: underline;
}
a{
  color: white;
  text-decoration: underline;
  font-size: 1.1rem;
}
a:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 700px){
  .App{
    width: 90%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  p, h3{
    font-size: 1.3rem;
  }
  .one-poem{
    margin-top: 10vh;
  }
  .modal{
    top: 0;
    right: 0;
  }
  .modal-content{
    width: 70%;
    margin-left: 1rem;
    margin-top: 10vh;
  }
  .info-button{
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 2rem;
    margin-bottom: 0;
  }
}